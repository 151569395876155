<template>
  <el-main class="rh-form">
    <h1>Editer l'utilisateur</h1>
    <el-row style="width: 436px" v-loading="userLoadStatus == 1">
      <el-col>
        <users-form ref="formUser" />
      </el-col>
    </el-row>
    <el-divider />
    <!-- boutons -->
    <div style="margin-top: 8px">
      <el-button @click="onBack">Retour</el-button>
      <el-button type="primary" @click="onSubmit">Enregistrer</el-button>
      <el-button @click="onCancel">Annuler</el-button>
    </div>
  </el-main>
</template>
<script>
import UsersForm from "@/components/UsersForm";
import { trimStringInObj } from "@/fonctions";
export default {
  name: "UsereditView",
  components: { "users-form": UsersForm },
  data() {
    return {
      //
    };
  },
  created() {
    this.$store.dispatch("user/loadUser", this.idUser);
  },
  mounted() {
    this.afficheData();
  },
  computed: {
    idUser() {
      // récupère le id de l'user dans l'url
      return String(this.$route.params.iduser);
    },
    userLoadStatus() {
      return this.$store.state.user.userLoadStatus;
    },
    userUpdateStatus() {
      // 1 = action en cours, 2 = succès, 3 = échec
      return this.$store.state.user.userUpdateStatus;
    },
  },
  methods: {
    afficheData() {
      if (this.userLoadStatus == 2) {
        // charge les données de l'user
        const user = this.$store.state.user.user;
        // garde les données chargées dans le localStorage pour les comparée avec les modifs
        localStorage.userloaded = JSON.stringify(user);
      } else if (this.userLoadStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec du chargement des données !" });
      }
    },
    onSubmit() {
      this.$refs.formUser.$refs.formUser.validate((valid) => {
        if (valid) {
          let userdata = this.$store.state.user.user;
          // enlever les espaces avant et après dans les string
          trimStringInObj(userdata);
          // vérifie si un user avec le même email existe déjà
          const email = userdata.email;
          const id = this.idUser;
          const UsersInBD = this.$store.getters["user/UsersInBD"];
          const userdataStr = JSON.stringify(userdata);
          if (localStorage.userloaded == userdataStr) {
            this.$message({
              message: "Les données n'ont pas été modifiées",
            });
          } else if (
            UsersInBD.filter((m) => m[0] != id && m[1] == email).length > 0
          ) {
            this.$alert(
              "Un utilisateur est déjà enregistré avec cet email !",
              "Attention",
              {
                confirmButtonText: "Fermer",
                dangerouslyUseHTMLString: true,
              }
            );
            return false;
          } else {
            // si plus d'erreur maj l'enregistrement
            this.$store.dispatch("user/updateUser", userdata);
          }
        } else {
          return false;
        }
      });
    },
    onCancel() {
      const userdataStr = JSON.stringify(this.$store.state.user.user);
      if (localStorage.userloaded != userdataStr) {
        let dataObj = JSON.parse(localStorage.userloaded);
        this.$store.commit("user/setUser", dataObj);
        this.$message({
          message: "Les modifications ont été annulées",
          type: "warning",
        });
      } else {
        this.$message({
          message: "Les données n'ont pas été modifiées",
        });
      }
    },
    onBack() {
      const userdataStr = JSON.stringify(this.$store.state.user.user);
      if (localStorage.userloaded == userdataStr) {
        // si pas de modifications
        // retourne à la liste des users
        this.$router.push("/users");
      } else {
        this.$confirm(
          "Continuer sans enregistrer les modification ?",
          "Attention !",
          {
            confirmButtonText: "Continuer",
            cancelButtonText: "Annuler",
            type: "warning",
          }
        ).then(() => {
          // retourne à la liste des users
          this.$router.push("/users");
        });
      }
    },
  },
  watch: {
    userLoadStatus() {
      // ne lance l'affichage des données que si toutes les données sont chargées
      // lorsqu'on charge directement la page
      this.afficheData();
    },
    userUpdateStatus() {
      // gère les messages et la navigation après soumission du formulaire
      if (this.userUpdateStatus == 2) {
        // message succès
        this.$message({
          message: "Les modifications ont été enregistrées.",
          type: "success",
        });
        // place les données sauvegardée dans le localStorage (nouvelles données initiales)
        localStorage.userloaded = JSON.stringify(this.$store.state.user.user);
      } else if (this.userUpdateStatus == 3) {
        // message échec
        this.$message.error({ message: "Echec de l'enregistrement !" });
      }
    },
  },
};
</script>
